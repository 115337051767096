import {PureComponent} from 'react';
import Loader from "Component/Loader/Loader.component";

import './Mostviewed.style';
import ContentWrapper from 'Component/ContentWrapper';
import ProductCard from 'Component/ProductCard';

import Decoration from "Component/Decoration/Decoration.component";
import '@scandipwa/scandipwa/src/route/CmsPage/CmsPage.style.scss';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination, FreeMode } from "swiper";
// Import Swiper styles
// import 'swiper/swiper-bundle.css';
import { getIndexedProduct } from 'Util/Product';
export class Mostviewed extends PureComponent {

    renderProductCard = this._renderProductCard.bind(this);

    _renderProductCard(product, i) {

        // product.options = (product.options) ? product.options : [];
        // product.configurable_options = {};
        const fullProduct = getIndexedProduct(product);

        const {
            productCardProps: {
                siblingsHaveBrands,
                siblingsHavePriceBadge,
                siblingsHaveTierPrice,
                siblingsHaveConfigurableOptions
            },
            productCardFunctions: {
                setSiblingsHaveBrands,
                setSiblingsHavePriceBadge,
                setSiblingsHaveTierPrice,
                setSiblingsHaveConfigurableOptions
            }
        } = this.props;
        const {id = i} = fullProduct;

        return (
            <ProductCard
                block="ProductLinks"
                elem="Card"
                product={fullProduct}
                key={id}
                siblingsHaveBrands={siblingsHaveBrands}
                siblingsHavePriceBadge={siblingsHavePriceBadge}
                siblingsHaveTierPrice={siblingsHaveTierPrice}
                siblingsHaveConfigurableOptions={siblingsHaveConfigurableOptions}
                setSiblingsHaveBrands={setSiblingsHaveBrands}
                setSiblingsHavePriceBadge={setSiblingsHavePriceBadge}
                setSiblingsHaveTierPrice={setSiblingsHaveTierPrice}
                setSiblingsHaveConfigurableOptions={setSiblingsHaveConfigurableOptions}
            />
        );
    }

    renderHeading(activeRule) {

        const {title, isLoading, linkType, activeRules} = this.props;

        if (isLoading || typeof activeRules === "undefined") {
            return (
                <h2 block="ProductLinks" elem="Title">
                </h2>
            );
        }
        // const renderItems = activeRules.filter(s => s.includes(linkType))[0];
        return (
            <>
                {/*<Decoration direction="left" angle="topLeft" type="type1"/>*/}
            <h1 block="CmsPage" elem="Heading">
                {this.props.items[activeRule].title}
            </h1>
            </>
        );
    }

    renderMostViewed(activeRule) {

        const {items, isLoading, linkType, activeRules, isMobile} = this.props;

        if (isLoading || !activeRules) {
            return null;
        }

        return (
            <Swiper
                className={(items[activeRule].items.length > 4) ? 'WithPager' : ''}
                // effect={"fade"}
                navigation={false}
                pagination={{
                    clickable: true
                }}
                pagination={false}
                // loop={true}
                // loopFillGroupWithBlank={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                }}
                // centeredSlides={isMobile}
                // initialSlide={2}
                modules={[Autoplay, Navigation, Pagination, FreeMode]}
                // className="mySwiper"
                spaceBetween={(isMobile) ? 16:24}
                slidesPerView={4}
                // onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)}
                freeMode={true}
                breakpoints={
                    {
                    0: {
                        slidesPerView: 1,
                        pagination: {
                            enabled: false,
                        },
                        navigation: {
                            enabled: false
                        }
                    },
                    375: {
                        slidesPerView: 2,
                        pagination: {
                            enabled: false,
                        },
                        navigation: {
                            enabled: false
                        }
                    },
                        580: {
                            slidesPerView: 3,
                        },
                        768: {
                            slidesPerView: 3,
                        },
                        811: {
                            slidesPerView: 3,
                        },
                        1180: {
                            slidesPerView: 3,
                        },
                        1440: {
                            slidesPerView: 4,
                        },
                }
                }
            >

                {
                    items[activeRule].items.map((product, index) => {
                        // product.options = (product.options) ? product.options : [];
                        // console.log(product);
                        if (typeof product === 'undefined') {
                            return null;
                        }

                        return <SwiperSlide>{this.renderProductCard(product, index)}</SwiperSlide>
                    })
                }
            </Swiper>
        )

    }

    renderMostviewedLoadingSkeleton() {
        return (
            <>
                <section aria-label="Linked products" className="ProductLinks-MostViewed">
                    <div className="Uniques-Wrapper ContentWrapper">
                        <div block="CmsPage" elem="SectionPlaceholder" mix={ { block: 'Title loading' } }>heading</div>
                        <div className="skeletonWrapper">
                            {Array.apply(0, Array(4)).map(function (x, i) {
                                return (
                                    <span key={i} block="CmsPage" elem="SectionPlaceholder">
                            </span>
                                )
                            })}
                        </div>
                    </div>
                </section>
            </>
        );
    }

    render() {
        // console.clear();
        // console.log(this.props);
        const {isLoading, items, activeRules, linkType} = this.props;

        if (isLoading) {
            return this.renderMostviewedLoadingSkeleton();
        }

        if (items.length === 0) {
            return null;
        }

        if (typeof activeRules === 'undefined') {
            return this.renderMostviewedLoadingSkeleton();
            return (
                <ContentWrapper
                    // mix={ { block: 'ProductLinks' } }
                    wrapperMix={ { block: 'Amasty', elem: 'Mostviewed' } }
                    label={ __('Amasty Mostviewed') }
                    // className="amasty-mostviewed"
                >
                    <Loader isLoading={isLoading}/>
                </ContentWrapper>
            )
        }

        return (
            <>
                {activeRules.map((activeRule, index) => {
                    // console.log(activeRule);
                    return (
                        <ContentWrapper
                            className=''
                            key={index}
                            mix={{block: 'ProductLinks', elem: 'MostViewed'}}
                            wrapperMix={{block: 'ProductLinks', elem: 'Wrapper'}}
                            label={__('Linked products')}
                        >
                            {this.renderHeading(activeRule)}
                            <Loader isLoading={isLoading}/>
                            {this.renderMostViewed(activeRule)}
                            {/*{this.renderDecoration()}*/}
                        </ContentWrapper>
                    )
                })
                }


            </>
        )
    }

    renderDecoration() {
        return null;
        return (
            <div className="most-viewed-deco">
                <Decoration direction="left" angle="bottomLeft" type="type1"/>
                <Decoration direction="center" angle="custom" type="type1"/>
                <Decoration direction="right" angle="custom" type="type1"/>
            </div>
        )
    }
}

export default Mostviewed;
