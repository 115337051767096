import {lazy} from "react";
import {Route} from 'react-router-dom';
import {withStoreRegex} from "Component/Router/Router.component";
import CmsBlockQuery from "Query/CmsBlock.query";
import {COLUMN_MAP} from "Component/Footer/Footer.config";
import getStore from "Util/Store";
export const MAX_POSITION = 9999;
export const INCREASED_POSITION = 10;
export const TrackOrderPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "contact" */ './../route/TrackOrderPage'));

export const renderColumns = (args, callback, instance) => {

    const state = getStore().getState();
    let {
        magenear_trackorder_general_enabled,
        magenear_trackorder_footer_column
    } = state.ConfigReducer;

    if (magenear_trackorder_general_enabled === "0") {
        return callback(...args);
    }

    //inject track order link to footer, NUMBER means column

    // const columnNumber = parseInt(magenear_trackorder_footer_column) - 1;
    // const targetColumn = COLUMN_MAP[columnNumber].items.filter(i => !(i.href && i.href === '/' + 'track-order'));
    // COLUMN_MAP[columnNumber].items = targetColumn;

    const {categories = {}} = instance.props;
    const footerCategories = JSON.parse(categories);
    let footerColumnsCategories = footerCategories.concat(COLUMN_MAP);
    let columnNumber;
    columnNumber = Object.keys(footerColumnsCategories).filter(function(key) {return footerColumnsCategories[key]['columnIdentify'] === 'account'})[0];
    if (typeof columnNumber === 'undefined') {
        columnNumber = parseInt(magenear_trackorder_footer_column) - 1;
    }
    const targetColumn = footerColumnsCategories[columnNumber].items.filter(i => !(i.href && i.href === '/' + 'track-order'));

    footerColumnsCategories[columnNumber].items = targetColumn;

    targetColumn.push({href:'/' + 'track-order', title: __('Track Order')});

    return callback(...args);

};
const SWITCH_ITEMS_TYPE = (originalMember) => {

    const state = getStore().getState();
    let {
        magenear_trackorder_general_enabled
    } = state.ConfigReducer;

    if (magenear_trackorder_general_enabled === "0") {
        return originalMember;
    }

    const maxPosition = Math.max.apply(
        Math, originalMember.filter(route => route.position < MAX_POSITION
        ).map(function (route) {
            if (route.name === "URL_REWRITES") {
                route.position = MAX_POSITION;
            }
            return route.position;
        })
    );

    return [
        ...originalMember,
        {
            component: <Route path={ withStoreRegex('/track-order') } render={ (props) => <TrackOrderPage { ...props } /> } />,
            // component: <Route path={withStoreRegex('/track-order')} render={() => <TrackOrderPage/>}/>,
            position: maxPosition - INCREASED_POSITION,
            name: 'track-order'
        }
    ];

};

const _getCmsBlock = (args, callback, instance) => {

    const {identifier} = instance.props;

    instance.fetchData(
        [CmsBlockQuery.getQuery({identifiers: [identifier]})],
        ({cmsBlocks: {items}}) => {
            if (!items.length) {
                return;
            }
            instance.setState({cmsBlock: items[0]});
        },
        (e) => {
            instance.setState({
                cmsBlock: {
                    content: '<p>To track your order, please enter your Email ID or Mobile Number and Order ID and press the "Track Order" button. You can find your Order ID, in your order confirmation email which we sent.</p>',
                    disabled: false,
                    identifier: 'track_order_page_block',
                    title: 'Track Order'
                }
            });

        },
    );
};

const stateMap = (member) => {
    member['track-order'] = {
        title: true,
        back: true,
    }
    return member;
};

export default {
    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE
        }
    },
    'Component/CmsBlock/Container': {
        'member-function': {
            _getCmsBlock
        }
    },
    'Component/Header/Component': {
        'member-property': {
            stateMap: stateMap
        }
    },
    'Component/Footer/Component': {
        'member-function': {
            renderColumns
        }
    }
};

