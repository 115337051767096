import {PureComponent} from 'react';
import Loader from "Component/Loader/Loader.component";
import Image from "Component/Image";
import {ImageRatio} from "Component/Image/Image.type";
import {FieldType} from "Component/Field/Field.config";
import Field from "Component/Field";
import CustomPopUp from './../../../../../src/component/CustomPopUp/CustomPopUp.component';
import FieldGroup from "@scandipwa/scandipwa/src/component/FieldGroup";
import {CartCouponComponentProps} from "@scandipwa/scandipwa/src/component/CartCoupon/CartCoupon.type";
import {formatPrice} from 'Util/Price';
import './PinChecker.style';
import {fetchQuery} from 'Util/Request/Query';
import BrowserDatabase from 'Util/BrowserDatabase';
import ShippingSystemQuery from './../../query/ShippingSystem.query';
import {ValidationInputType} from "@scandipwa/scandipwa/src/util/Validator/Config";

export class PinChecker extends PureComponent {

    state = {
        selectedCourier: false,
        isRecommended: false,
    }

    // checkAvailability = this._checkAvailability.bind(this);

    checkAvailability() {
        setTimeout(() => {
            const activedOptions = document.querySelectorAll('#deliveryLocationPins .active');
            const deliveryLocationPins = document.getElementById("deliveryLocationPins");
            if (deliveryLocationPins && deliveryLocationPins.classList.contains("active") && !activedOptions.length) {
                deliveryLocationPins.classList.remove('active');
            }
        }, 300);

        const pinCodeCheckerElement = document.getElementById("pinCodeNote");

        if (pinCodeCheckerElement?.classList.contains("pinCodeChecked")) {
            pinCodeCheckerElement?.classList.remove('pinCodeChecked');
            BrowserDatabase.deleteItem('selected_delivery_location');
            BrowserDatabase.deleteItem('selected_delivery_city');
            BrowserDatabase.deleteItem('selected_delivery_pincode');
            BrowserDatabase.deleteItem('selected_delivery_eta');
            document.getElementById("pinCode").value = '';
            if (typeof window.selectDeliveryLocationFindPinCode !== 'undefined') {
                window.selectDeliveryLocationFindPinCode(0);
            }
            return;
        }


        const pinCode = BrowserDatabase.getItem('selected_delivery_pincode');
        const {onCheckPinCode} = this.props;
        if (!pinCode) {
            document.getElementById("pinCodeWrapper")?.classList.add('error');
            setTimeout(() => {
                document.getElementById("pinCodeWrapper")?.classList.remove('error');
            }, 2000);
        }else {
            onCheckPinCode(pinCode);
        }

    }

    selectCourier(courierId = '') {
        const {couriers} = this.props;
        const selectedCourier = Object.entries(couriers).map(([index, courier]) => {
            // console.log(index);
            // console.log(courier);
            if (courierId === courier.courier_company_id) {
                return courier;
            }else {
                return false;
            }
        });
        const cart = BrowserDatabase.getItem('cart_totals');
        const cartId = BrowserDatabase.getItem('cart_id');
        const {items} = cart;
        // console.log(cart);
        // console.log(cartId);
        // console.log(window.localStorage);
        // console.log(JSON.parse(window.localStorage.cart_totals));

        // let zip = document.querySelector('input[name=postcode]').value;
        // console.log(zip);
        // document.getElementById("ProceedToBilling").setAttribute("disabled", true);
        fetchQuery(
            ShippingSystemQuery.getShippingSystem(cart.id, 0, JSON.stringify(selectedCourier))
            // ShippingSystemQuery.getShippingSystem(window.localStorage.cart_totals)
        ).then(
            /** @namespace Component/PinChecker/Container/requestDownloadable/success */
            (
                /** @namespace Component/PinChecker/Container/PinCheckerContainer/requestDownloadable/fetchQuery/then */
                async (data) => {
                    let couriers = JSON.parse(data.shippingSystem.shippingsystem_data);
                    if (typeof window.onShippingSuccess !== 'undefined' && couriers) {
                        window.onShippingSuccess();
                        const cart = BrowserDatabase.getItem('cart_totals');
                        const {id} = cart;
                        if (typeof window.gstRequestUpdate !== 'undefined' && !!(id)) {
                            setTimeout(function () {
                                console.log('should gst update from pin checker');
                                window.gstRequestUpdate(id);
                            }, 1000);
                        }
                    }

                    // console.log(couriers);

                    const deliveryBox = document.getElementById('CheckoutDelivery').cloneNode(true);
                    const deliveryBoxHolder = document.getElementById('CheckoutDeliveryHolder');
                    deliveryBoxHolder.innerHTML = '';
                    deliveryBoxHolder.append(deliveryBox);
                    //
                    const button = document.querySelector("#CheckoutDeliveryHolder .CartPage-ItemsCourierWrapper .CartPage-ItemsCourierWrapper-Actions #ChangeShippingMethod");
                    //
                    // const button = deliveryBoxHolder.getElementById("ChangeShippingMethod");
                    //
                    console.log(button);
                    try {
                        button.addEventListener("click", (event) => {
                            // console.clear();
                            // console.log(event.target);
                            document.getElementById("ChangeShippingMethod")?.click();
                        });
                    }catch (e) {

                    }
                    // window.dispatchEvent(new Event("resize"));


                    console.log(deliveryBoxHolder);
                    // this.setState({couriers, items, isLoading: false});

                }),
            /** @namespace Component/PinChecker/Container/PinCheckerContainer/requestDownloadable/fetchQuery/then/catch */
            (err) => {
                // showErrorNotification(getErrorMessage(err));
                // this.setState({isLoading: false});
            }
        );
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    // componentDidUpdate() {
    //     this.handleResize();
    // }

    handleResize() {
        const ProductActions = document.getElementById("ProductActions");
        const ResultEtaAuto = document.getElementById("ResultEtaAuto");
        const ResultEta = document.getElementById("ResultEta");

        const parent = parseFloat(window.getComputedStyle(ProductActions).width);
        const source = parseFloat(window.getComputedStyle(ResultEtaAuto).width);

        if (parseInt(source) + 32 >= parseInt(parent)) {
            ResultEta.classList.add('lineBreak');
        }else if (parseInt(source) + 32 <= parseInt(parent)) {
            ResultEta.classList.remove('lineBreak');
        }

    }


    _handleCourierChange(event, field) {
        const {value = ''} = field || {};
        // const {onCourierClick} = this.props;

        console.clear();
        // console.log(this.state);
        console.log(this.props);
        // console.log(event.target);
        // console.log(event.target.value);
        // onCourierClick(parseInt(event.target.value));
        this.selectCourier(parseInt(event.target.value));
        console.log(event.target);
        console.log(event.target.getAttribute('is_recommended'));
        this.setState({
            selectedCourier: parseInt(event.target.value),
            isRecommended: !!(event.target.getAttribute('is_recommended'))
        })
        // this.forceUpdate();
        console.log('delivery was changed');
        // document.getElementById("SHIPPING_STEP").submit();

    }

    handleCourierChange = this._handleCourierChange.bind(this);

    // _checkAvailability() {
    //
    //     document.getElementById("CustomPopUp")?.click();
    // }

    renderRecommended(recommended) {
        if (!recommended) {
            return null;
        }
        // console.log(recommended)

        // this.setState({selectedCourier: recommended.courier_company_id});
        const {selectedCourier = recommended.courier_company_id} = this.state;
        // console.log(this.state);
        // console.log(this.props);
        // console.log(recommended);
        return (
            <div block="CartPage" elem="ItemsCourierWrapper">
                <Field
                    type={FieldType.CHECKBOX}
                    attr={{
                        id: 'recommendedCourier',
                        name: 'recommendedCourier',
                        // value: 'recommendedCourier',
                        checked: (selectedCourier),
                    }}
                    // events={ {
                    //     onChange: this.setTACAccepted,
                    // } }
                    mix={{block: 'Courier', elem: 'Recommended'}}
                />
                <div block="CartPage" elem="ItemsCourierWrapper-Content">
                    <div block="CartPage"
                         elem="ItemsCourierWrapper-Name">{recommended.courier_name}<span>Recommended</span></div>
                    <div block="CartPage" elem="ItemsCourierWrapper-Rate">Rate: {formatPrice(recommended.rate)}</div>
                    <div block="CartPage" elem="ItemsCourierWrapper-Eta">Est delivery: {recommended.estimated_delivery_days} day{(recommended.estimated_delivery_days > 1) ? 's' : ''}</div>
                </div>
                {/*{this.renderActions()}*/}
            </div>
        )
    }

    renderActions(){
        const{items} = this.props;
        return (
            <div block="CartPage" elem="ItemsCourierWrapper-Actions">
                <CustomPopUp startIcon={
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12.0003 7.16671L9.33368 4.50004M1.66699 14.8334L3.92324 14.5827C4.1989 14.5521 4.33673 14.5367 4.46556 14.495C4.57985 14.458 4.68862 14.4058 4.78892 14.3396C4.90196 14.2651 5.00002 14.167 5.19614 13.9709L14.0003 5.16671C14.7367 4.43033 14.7367 3.23642 14.0003 2.50004C13.264 1.76366 12.0701 1.76366 11.3337 2.50004L2.52948 11.3042C2.33336 11.5003 2.2353 11.5984 2.16075 11.7114C2.09461 11.8117 2.04234 11.9205 2.00533 12.0348C1.96363 12.1636 1.94831 12.3015 1.91769 12.5771L1.66699 14.8334Z"
                            stroke="#A10101" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                } heading="Change Shipping Method" buttonTitle="Change" source="change-shipping-method" content={
                    <>
                        {this.renderCouriers()}
                        {/*<div block="CartPage" elem="Items" aria-label="List of items in cart">*/}
                        {/*    {items.map((item) => (*/}
                        {/*        <div block="CartItem">*/}
                        {/*            {this.renderItem(item)}*/}
                        {/*        </div>*/}
                        {/*    ))}*/}
                        {/*</div>*/}
                    </>
                }/>
            </div>
        )
    }

    renderCourier(courier, fieldSelectorType = FieldType.RADIO) {

        const {selectedCourier = (courier.is_recommended) ? courier.courier_company_id : false} = this.state;

        // console.log(courier)
        // console.log(this.state);
        // console.log(selectedCourier);
        // console.log(courier.courier_company_id);
        // console.log(this.props);
        // console.log(fieldSelectorType);
        return (
            <div block="CartPage" elem="ItemsCourierWrapper"
                 mix={{block: (courier.is_recommended) ? 'recommended' : ''}}>
                <Field
                    type={fieldSelectorType}
                    attr={{
                        id: `courier${courier.courier_company_id}`,
                        name: `courier${courier.courier_company_id}`,
                        checked: (selectedCourier === courier.courier_company_id),
                        value: courier.courier_company_id,
                        is_recommended: courier.is_recommended
                    }}
                    events={{
                        onChange: this.handleCourierChange,
                    }}
                    mix={{block: 'Courier', elem: 'Recommended'}}
                />
                <div block="CartPage" elem="ItemsCourierWrapper-Content">
                    <div block="CartPage" elem="ItemsCourierWrapper-Chooser">
                        <div id="selectedCourier" block="CartPage" elem="ItemsCourierWrapper-Name">{courier.courier_name}{(courier.is_recommended) ?
                            <span>Recommended</span> : ''}</div>
                        <div block="CartPage" elem="ItemsCourierWrapper-Rate">Rate: {formatPrice(courier.rate)}</div>
                    </div>
                    <div block="CartPage" elem="ItemsCourierWrapper-Eta">Est delivery: {courier.etd} ({courier.estimated_delivery_days} day{(courier.estimated_delivery_days > 1) ? 's' : ''})</div>
                </div>
                {/*{*/}
                {/*    (fieldSelectorType = FieldType.CHECKBOX) ? this.renderActions() : ''*/}
                {/*}*/}

                {
                    (fieldSelectorType === 'checkbox') ? this.renderActions() : ''
                }
            </div>
        )
    }

    renderCourierById(id, freeShipping = false) {
        const {couriers, shippingCharge} = this.props;

        let freeShippingCourier = false;

        const courier = Object.entries(couriers).map(([index, courier]) => {
            // console.log(index);
            // console.log(courier);
            if (id === courier.courier_company_id) {
                freeShippingCourier = courier;
                return this.renderCourier(courier, FieldType.CHECKBOX)
            }else {
                return false;
            }
        });

        if (shippingCharge === 'free' && freeShipping) {
            console.clear();
            console.log(freeShippingCourier);
            if (!freeShippingCourier) {
                return null;
            }
            return (
                <>
                <div block="CartItem" elem="FreeShipping">
                    <div block="CartPage" elem="ItemsCourierWrapper-Eta">Est delivery: {freeShippingCourier.etd} ({freeShippingCourier.estimated_delivery_days} day{(freeShippingCourier.estimated_delivery_days > 1) ? 's' : ''})</div>
                    <div block="CartPage" elem="ItemsCourierWrapper-Rate">FREE</div>
                </div>

                </>
            );
        }

        if (shippingCharge === 'free' && !freeShipping) {
            return null;
        }

        return courier;

    }
    renderCouriers() {
        const {isRecommended} = this.state;
        const {couriers} = this.props;

        return (
           <>
               {this.renderNote(isRecommended)}
               <FieldGroup
                   validationRule={{
                       selector: '[type="radio"]',
                   }}
                   validateOn={['onChange']}
               >
                   {Object.values(couriers).map((courier) => this.renderCourier(courier))}
               </FieldGroup>
           </>
        );


        // return couriers.map((courier) => {
        //     console.log(courier);
        //
        //     return (
        //         <>
        //         1
        //         </>
        //     );
        // });
    }

    renderNote(isRecommended) {
        return (
            <>
                {
                    (isRecommended) ?
                        <div block="CourierNotice" elem="Recommended">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_1200_17754)">
                                    <path d="M8.00021 16.0004C12.4186 16.0004 16.0004 12.4186 16.0004 8.00021C16.0004 3.58182 12.4186 0 8.00021 0C3.58182 0 0 3.58182 0 8.00021C0 12.4186 3.58182 16.0004 8.00021 16.0004Z" fill="#059E00"/>
                                    <path d="M6.80382 9.56544C6.93439 9.44159 7.02591 9.35888 7.11281 9.27156C8.37649 8.00928 9.63961 6.74658 10.9022 5.48347C10.9756 5.40958 11.047 5.33233 11.1259 5.26474C11.3946 5.03341 11.6877 5.02921 11.9127 5.2492C12.1377 5.46919 12.1407 5.80799 11.8896 6.06031C10.3421 7.61507 8.79072 9.16548 7.2354 10.7116C6.96587 10.9794 6.68123 10.9752 6.40457 10.7036C5.63852 9.95098 4.87766 9.19067 4.12197 8.42267C3.85916 8.15482 3.85916 7.84373 4.0846 7.61367C4.31005 7.3836 4.65473 7.40039 4.91922 7.66363C5.5414 8.28203 6.15393 8.90883 6.80382 9.56544Z" fill="white"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_1200_17754">
                                        <rect width="16" height="16" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                            <span>
                               Best courier method is selected for this shipment. Changing it may result
                           in the delay or loss of shipment. Read rules here.
                           </span>
                        </div>
                        :
                        <div block="CourierNotice" elem="NotRecommended">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.509661 12.2659L6.92069 2.00789C7.27501 1.44096 7.45217 1.15749 7.6833 1.06228C7.88492 0.979239 8.11508 0.979239 8.3167 1.06228C8.54783 1.15749 8.72499 1.44096 9.07931 2.00789L15.4903 12.2659C15.8459 12.8348 16.0237 13.1193 15.9975 13.3528C15.9745 13.5565 15.8594 13.7415 15.6806 13.8619C15.4756 14 15.1207 14 14.411 14H1.58898C0.879253 14 0.524391 14 0.319424 13.8619C0.140648 13.7415 0.0254654 13.5565 0.00254489 13.3528C-0.0237334 13.1193 0.154065 12.8348 0.509661 12.2659Z" fill="#D58C24"/>
                                <path d="M8 5.69629V8.58451M8 11.4727H8.00779" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <span>
                               The selected shipping method is not recommended by us. We’ll take no responsibility for
                               any delay or loss in the shipment. Read rules here.
                           </span>
                        </div>
                }
            </>
        )
    }

    renderItems() {
        // console.clear();
        // console.log(this.state);
        // console.log(this.props);

        const {couriers, couriers: {status, message = false}, items, shippingCharge} = this.props;

        let recommended = false;

        if (typeof couriers[0] !== 'undefined') {
            recommended = couriers[0];
        }

        let {selectedCourier, isRecommended} = this.state;

        if (typeof isRecommended === 'undefined') {
            isRecommended = false;
        }

        if (selectedCourier) {
            // document.getElementById("ProceedToBilling").removeAttribute("disabled");
        }

        return (
            <>
                {this.renderActions()}
                {(shippingCharge === 'free') ?
                    <div block="CartItem" elem="FreeShippingNote">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_1360_18597)">
                                <path d="M8.00021 16.0004C12.4186 16.0004 16.0004 12.4186 16.0004 8.00021C16.0004 3.58182 12.4186 0 8.00021 0C3.58182 0 0 3.58182 0 8.00021C0 12.4186 3.58182 16.0004 8.00021 16.0004Z" fill="#059E00"/>
                                <path d="M6.80382 9.56544C6.93439 9.44159 7.02591 9.35888 7.11281 9.27156C8.37649 8.00928 9.63961 6.74658 10.9022 5.48347C10.9756 5.40958 11.047 5.33233 11.1259 5.26474C11.3946 5.03341 11.6877 5.02921 11.9127 5.2492C12.1377 5.46919 12.1407 5.80799 11.8896 6.06031C10.3421 7.61507 8.79072 9.16548 7.2354 10.7116C6.96587 10.9794 6.68123 10.9752 6.40457 10.7036C5.63852 9.95098 4.87766 9.19067 4.12197 8.42267C3.85916 8.15482 3.85916 7.84373 4.0846 7.61367C4.31005 7.3836 4.65473 7.40039 4.91922 7.66363C5.5414 8.28203 6.15393 8.90883 6.80382 9.56544Z" fill="white"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_1360_18597">
                                    <rect width="16" height="16" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <span>Shipping is provided for FREE in this order</span>
                    </div>
                    :''}
                <div block="CartPage" elem="Items" mix={{block: (shippingCharge === 'free') ? 'free' : ''}} aria-label="List of items in cart">
                    {(shippingCharge === 'free') ? this.renderCourierById(selectedCourier, true):''}
                    {items.map((item) => (
                        <div block="CartItem">
                            {this.renderItem(item)}
                        </div>
                    ))}
                </div>
                {
                    (status === 404) ? <span block="CartPage" elem="ItemsNoCouriers">{message}</span> : ''
                    // (status === 404) ? <span block="CartPage" elem="ItemsNoCouriers">Please specify correct delivery location</span> : ''
                    // There are no shipping methods available, try different address.
                }

                {
                    (selectedCourier) ? this.renderCourierById(selectedCourier) : this.renderRecommended(recommended)
                }

                {
                    (selectedCourier) ? (isRecommended) ? '' : this.renderNote(isRecommended) : ''
                }
            </>
        )
    }

    renderItem(item) {
        const {
            quantity,
            product: {name = '', type_id} = {},
        } = item;

        return (
            <div block="CartItem" elem="Wrapper">
                {this.renderImage(item)}
                <div block="CartItem" elem="CartItemRows">
                    <div block="CartItem" elem="ProductInfo">
                        <div block="CartItem" elem="Title">
                            <p block="CartItem" elem="Heading">{name}</p>
                        </div>
                    </div>
                    <div block="CartItem" elem="OptionsQty">
                        {this.renderProductConfigurations(item)}
                        {
                            (type_id !== 'simple') ?
                                <svg block="CartItem" elem="OptionsQtySeparator" width="4" height="4" viewBox="0 0 4 4"
                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M3.432 2.096C3.432 2.44 3.304 2.736 3.048 2.984C2.8 3.232 2.504 3.356 2.16 3.356C1.816 3.356 1.516 3.232 1.26 2.984C1.012 2.736 0.888 2.44 0.888 2.096C0.888 1.744 1.012 1.444 1.26 1.196C1.516 0.948 1.816 0.824 2.16 0.824C2.512 0.824 2.812 0.948 3.06 1.196C3.308 1.444 3.432 1.744 3.432 2.096Z"
                                        fill="#948F8F"/>
                                </svg>
                                : ''
                        }

                        <p block="CartItem" elem="Quantity">
                            {__('Quantity: %s', quantity)}
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    renderProductConfigurations(item) {
        const {isMobile} = this.props;
        let optionsLabels = this.getConfigurableOptionsLabels(item);

        if (!optionsLabels.length) {
            return null;
        }

        if (isMobile) {
            return (
                <div
                    block="CartItem"
                    elem="Options"
                >
                    {optionsLabels.map(label => {
                        console.log(label);
                        return (
                            <>
                                <span block="CartItem" elem="OptionSeparator">,&nbsp;</span>
                                <span block="CartItem" elem="Option">{label}</span>
                            </>
                        )
                    })}
                </div>
            );
        }
        return (
            <div
                block="CartItem"
                elem="Options"
            >
                {optionsLabels.join(', ')}
            </div>
        );
    }

    getConfigurableOptionsLabels(item) {
        const {
            configurable_options = [],
            product: {
                variants,
            } = {},
        } = item;

        if (!variants || !configurable_options) {
            return [];
        }

        return (configurable_options).map(({option_label, value_label}) => option_label + ': ' + value_label);
    }

    renderImage(item) {
        const {
            product: {name = '', thumbnail} = {},
        } = item;

        return (
            <>
                <Image
                    src={thumbnail.url}
                    mix={{
                        block: 'CartItem',
                        elem: 'Picture',
                    }}
                    ratio={ImageRatio.IMG_CUSTOM}
                    alt={`Product ${name} thumbnail.`}
                />
                <img
                    style={{display: 'none'}}
                    alt={name}
                    src={thumbnail.url}
                />
            </>
        );
    }

     numberOnly(id) {
        // Get element by id which passed as parameter within HTML element event
        var element = document.getElementById(id);
        // This removes any other character but numbers as entered by user
        element.value = element.value.replace(/[^0-9]/gi, "");
    }

    render() {

        const {checkedPinCode, checkedPinCodeEta, isLoading} = this.props;

        setTimeout(() => {

            const activedOptions = document.querySelectorAll('#deliveryLocationPins .active');

            const deliveryLocationPins = document.getElementById("deliveryLocationPins");
            if (deliveryLocationPins && deliveryLocationPins.classList.contains("active") && !activedOptions.length) {
                deliveryLocationPins.classList.remove('active');
            }
        }, 300);

        return (
            <>

                <div id="pinCodeNote" block="PinCodeChecker" elem="Note">
                    <span>Enter pincode to get estimated delivery dates</span>
                    <div block="PinCodeChecker" elem="Wrapper">

                        <div id="pinCodeWrapper" block="PinCodeChecker" elem="Input">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9 9.75C10.2426 9.75 11.25 8.74264 11.25 7.5C11.25 6.25736 10.2426 5.25 9 5.25C7.75736 5.25 6.75 6.25736 6.75 7.5C6.75 8.74264 7.75736 9.75 9 9.75Z"
                                    stroke="#827D7D" stroke-width="1.38889" stroke-linecap="round"
                                    stroke-linejoin="round"/>
                                <path
                                    d="M9 16.5C12 13.5 15 10.8137 15 7.5C15 4.18629 12.3137 1.5 9 1.5C5.68629 1.5 3 4.18629 3 7.5C3 10.8137 6 13.5 9 16.5Z"
                                    stroke="#827D7D" stroke-width="1.38889" stroke-linecap="round"
                                    stroke-linejoin="round"/>
                            </svg>
                            <div id="ResultPinCode" block="PinCodeChecker" elem="ResultPinCode">{checkedPinCode}</div>
                            {
                                (!!checkedPinCodeEta && typeof checkedPinCodeEta === 'object') ?
                                    <div id="ResultEtaAuto" block="PinCodeChecker" elem="ResultEta" mix={{block:"auto"}}>Est delivery: {checkedPinCodeEta.etd} ({checkedPinCodeEta.estimated_delivery_days} day{(checkedPinCodeEta.estimated_delivery_days > 1) ? 's' : ''})</div> :
                                    <div id="ResultEta" block="PinCodeChecker" elem="ResultEta"
                                         mix={{block: "noService"}}>{checkedPinCodeEta}
                                        <div id="ResultEtaAuto"></div>
                                    </div>
                            }
                            <Loader isLoading={isLoading}/>
                            <Field
                                type={FieldType.NUMBER}
                                attr={{
                                    id: 'pinCode',
                                    name: 'pinCode',
                                    defaultValue: BrowserDatabase.getItem('selected_delivery_pincode'),
                                    // value: window.pinCodeChecker,
                                    placeholder: __('Pincode'),
                                    'aria-label': __('Pincode'),
                                    // oninput:this.numberOnly('pinCode'),
                                    maxlength: 6
                                }}
                                // events={{
                                //     onChange: this.handleCouponCodeChange,
                                // }}
                                validateOn={['onChange']}
                                validationRule={{
                                    match: (value) => {
                                        // console.log(value);

                                        if (!(value) || value.length < 6) {
                                            BrowserDatabase.deleteItem('selected_delivery_pincode');
                                            return true;
                                        }

                                        document.getElementById("pinCodeWrapper").classList.remove('error');

                                        if (value.length > 6) {
                                            document.getElementById("pinCode").value = value.slice(0,6);
                                        }
                                        BrowserDatabase.setItem(value.slice(0,6), 'selected_delivery_pincode')

                                        return true;
                                    },
                                    customErrorMessages: {
                                        onMatchFail: 'Please specify correct delivery location'
                                    }
                                }}

                                // validationRule={ {
                                //     isRequired: true,
                                //     inputType: ValidationInputType.NUMERIC,
                                // } }
                                // mix={ { mods: { hasError: isFieldWithError }, block: 'Field' } }
                            />
                        </div>
                        <button
                            block="PinCodeChecker"
                            elem="Button"
                            type={FieldType.BUTTON}
                            // mods={{isHollow: true}}
                            // disabled={ !enteredCouponCode }
                            onClick={this.checkAvailability.bind(this)}
                        >
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_1695_18180)">
                                    <path
                                        d="M12.0003 6.66671L9.33368 4.00004M1.66699 14.3334L3.92324 14.0827C4.1989 14.0521 4.33673 14.0367 4.46556 13.995C4.57985 13.958 4.68862 13.9058 4.78892 13.8396C4.90196 13.7651 5.00002 13.667 5.19614 13.4709L14.0003 4.66671C14.7367 3.93033 14.7367 2.73642 14.0003 2.00004C13.264 1.26366 12.0701 1.26366 11.3337 2.00004L2.52948 10.8042C2.33336 11.0003 2.2353 11.0984 2.16075 11.2114C2.09461 11.3117 2.04234 11.4205 2.00533 11.5348C1.96363 11.6636 1.94831 11.8015 1.91769 12.0771L1.66699 14.3334Z"
                                        stroke="#A10101" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_1695_18180">
                                        <rect width="16" height="16" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>

                            {/*{__('Check')}*/}
                        </button>
                        {
                            (!!checkedPinCodeEta && typeof checkedPinCodeEta === 'object') ?
                                <div id="ResultEta" block="PinCodeChecker" elem="ResultEta">Est delivery:<span>&nbsp;</span>{checkedPinCodeEta.etd} ({checkedPinCodeEta.estimated_delivery_days} day{(checkedPinCodeEta.estimated_delivery_days > 1) ? 's' : ''})</div> : <div id="ResultEta" block="PinCodeChecker" elem="ResultEta" mix={{block:"noService"}}>{checkedPinCodeEta}</div>
                        }
                    </div>
                </div>
            </>
        );

    }

}

export default PinChecker;
