import MostViewedWidget from "./../component/Mostviewed";

const renderContent = (args, callback, instance) => {

    const {
        type
    } = instance.props;

    if (type !== 'AmastyMostviewed') {
        return callback.apply(instance, args);
    }

    instance.renderMap['AmastyMostviewed'] = {
        component: MostViewedWidget
    }

    return callback.apply(instance, args);

};

export default {
    'Component/WidgetFactory/Component': {
        'member-function': {
            renderContent
        }
    }
};
