import {
    ProductListWidget as SourceProductListWidget,
    NewProducts as SourceNewProducts,
    HomeSlider as SourceHomeSlider,
    RecentlyViewedWidget as SourceRecentlyViewedWidget,
    WidgetFactoryComponent as SourceWidgetFactoryComponent,
} from 'SourceComponent/WidgetFactory/WidgetFactory.component';

import './WidgetFactory.override.style';

import { lazy, PureComponent, Suspense } from 'react';

import RenderWhenVisible from 'Component/RenderWhenVisible';
import { ReactElement } from 'Type/Common.type';


//TODO: implement ProductListWidget
export const ProductListWidget = SourceProductListWidget;

//TODO: implement NewProducts
export const NewProducts = SourceNewProducts;

//TODO: implement HomeSlider
export const HomeSlider = SourceHomeSlider;

//TODO: implement RecentlyViewedWidget
export const RecentlyViewedWidget = SourceRecentlyViewedWidget;

export class WidgetFactoryComponent extends SourceWidgetFactoryComponent {

    renderContent(): ReactElement {
        // console.clear();
        console.log(this.state);
        console.log(this.props);
        const {
            type,
            sliderId = 0,
            displayType,
            productsCount,
            showPager,
            storeId,
            title,
            conditionsEncoded,
            onLoad,
        } = this.props;
        const {
            component: Widget,
            fallback = () => <div />,
        } = this.renderMap[type] || {};

        if (Widget !== undefined) {
            return (
                <Suspense fallback={ fallback() }>
                    <RenderWhenVisible>
                        <Widget
                          sliderId={ sliderId }
                          displayType={ displayType }
                          productsCount={ productsCount }
                          showPager={ !!showPager }
                          storeId={ storeId }
                          title={ title }
                          conditionsEncoded={ conditionsEncoded }
                          onLoad={ onLoad }
                        />
                    </RenderWhenVisible>
                </Suspense>
            );
        }

        return null;
    }
};

export default WidgetFactoryComponent;
