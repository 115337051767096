import { connect } from 'react-redux';

import {
    mapStateToProps as sourceMapStateToProps,
    mapDispatchToProps as sourceMapDispatchToProps,
    MyAccountOrderContainer as SourceMyAccountOrderContainer,
} from 'SourceComponent/MyAccountOrder/MyAccountOrder.container';

import { RootState } from 'Util/Store/Store.type'
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    countries: state.ConfigReducer.countries
    // TODO extend mapStateToProps
});

import { Dispatch } from 'redux'
import {
    MyAccountOrderComponentProps,
    MyAccountOrderContainerPropsKeys
} from "Component/MyAccountOrder/MyAccountOrder.type";
import {OrderItem} from "Query/Order.type";
import {isSignedIn} from "Util/Auth/IsSignedIn";
import history from "Util/History";
import {appendWithStoreCode} from "Util/Url";
import {AccountPageUrl} from "Route/MyAccount/MyAccount.config";
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

export class MyAccountOrderContainer extends SourceMyAccountOrderContainer {
    containerProps(): Pick<
        MyAccountOrderComponentProps,
        MyAccountOrderContainerPropsKeys
    > {
        const { order: stateOrder, isLoading, activeTab } = this.state;
        const {
            display_tax_in_shipping_amount,
            is_allowed_reorder,
            rss_order_subscribe_allow,
            setTabSubheading,
            isMobile,
            //@ts-ignore
            countries
        } = this.props;

        return {
            display_tax_in_shipping_amount,
            isLoading,
            is_allowed_reorder,
            activeTab,
            rss_order_subscribe_allow,
            setTabSubheading,
            isMobile,
            //@ts-ignore
            countries,
            order: {
                ...stateOrder,
            } as OrderItem,
        };
    }

    async requestOrderDetails(orderId: string): Promise<void> {
        const {
            getOrderById,
            changeTabName,
            setTabSubheading,
        } = this.props;

        if (!isSignedIn()) {
            return;
        }

        const order = await getOrderById(Number(orderId));

        if (!order) {
            history.replace(appendWithStoreCode(`${AccountPageUrl.ORDER_HISTORY}`));

            return;
        }

        const { increment_id, status, id: uid } = order;

        // decode uid of order before setting into state
        order.id = atob(uid);

        // changeTabName((__('Order # %s', increment_id)));
        changeTabName((__('My Orders')));
        setTabSubheading(status);
        this.handleChangeHeaderState();
        this.setState({ order, isLoading: false });
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountOrderContainer);
