/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {Field} from 'Util/Query';

/** @namespace Query/ProductAlerts/Query */
export class Mostviewed {
    getMostViewed(location, entityId) {
        return new Field('amastyMostViewed').addFieldList([
            'mostviewed_data'
        ]).addArgument('location', 'String!', location).addArgument('entityId', 'Int', entityId)
    }
}

export default new Mostviewed();
