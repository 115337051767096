/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {PureComponent} from 'react';
import {connect} from 'react-redux';
import MostviewedQuery from './../../query/Mostviewed.query';
import {showNotification} from 'Store/Notification/Notification.action';
import {fetchQuery} from 'Util/Request/Query';
import {getErrorMessage} from 'Util/Request/Error';

import Mostviewed from './Mostviewed.component';
import ProductListQuery from "Query/ProductList.query";
import BrowserDatabase from 'Util/BrowserDatabase';

/** @namespace Component/Mostviewed/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
});

/** @namespace Component/Mostviewed/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showErrorNotification: (message) => dispatch(showNotification('error', message)),
    showSuccessNotification: (message) => dispatch(showNotification('info', message))
});

/** @namespace Component/Mostviewed/Container */
export class MostviewedContainer extends PureComponent {
    // static propTypes = {
    //     showErrorNotification: PropTypes.func.isRequired,
    //     showSuccessNotification: PropTypes.func.isRequired,
    // };

    state = {
        items: [],
        isLoading: false,
        siblingsHaveBrands: false,
        siblingsHavePriceBadge: false,
        siblingsHaveTierPrice: false,
        siblingsHaveConfigurableOptions: false
    };

    containerFunctions = {
        // onOrderIdClick: this.onOrderIdClick.bind(this)
    };

    componentDidMount() {
        // console.clear();
        // console.log(this.state);
        // console.log(this.props);
        window.isMostviewedRequested = true;
        this.requestDownloadable();
    }

    componentDidUpdate(prevProps, prevState) {

        const {location} = this.props;
        const {location: prevLocation} = prevProps;
        // console.log(location);
        // console.log(prevLocation);

        if (location !== prevLocation) {
            this.requestDownloadable();
        }
        return;

        // const { isRequested } = this.state;
        // this.setState({isRequested: true});
        // if (!isRequested) {
        //     this.setState({isRequested: true});
        // this.requestDownloadable();
        // }

        if (!window.isMostviewedRequested) {
            // window.isMostviewedRequested = true;
            // this.requestDownloadable();
        }

        // console.clear();
        // console.log(history.location);
        // console.log(this.state);
        // console.log(this.props);
        // console.log(prevState);
        // console.log(prevProps);
        // console.log(window.isMostviewedRequested);


        // this.setState({isRequested: true});
        // // this.requestDownloadable();
    }

    componentWillUnmount() {
        window.isPrefetchValueUsed = false;
    }

    containerProps = () => {


        const {
            isLoading,
            items: {activeRules: activeRules},
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveTierPrice,
            siblingsHaveConfigurableOptions
        } = this.state;
        let {linkType, device: {isMobile}} = this.props;


        if (typeof activeRules !== "undefined") {
            // console.log(activeRules.filter(s => s.includes(linkType)).length);

            linkType = (activeRules.filter(s => s.includes(linkType)).length) ? linkType : false;
        }

// console.log(linkType);

        return {
            isMobile,
            activeRules,
            isLoading,
            linkType,
            items: this._prepareDownloadableProps(),
            productCardFunctions: {
                setSiblingsHaveBrands: () => this.setState({siblingsHaveBrands: true}),
                setSiblingsHavePriceBadge: () => this.setState({siblingsHavePriceBadge: true}),
                setSiblingsHaveTierPrice: () => this.setState({siblingsHaveTierPrice: true}),
                setSiblingsHaveConfigurableOptions: () => this.setState({siblingsHaveConfigurableOptions: true})
            },
            productCardProps: {
                siblingsHaveBrands,
                siblingsHavePriceBadge,
                siblingsHaveTierPrice,
                siblingsHaveConfigurableOptions
            }
        };
    };

    _prepareDownloadableProps() {
        const {items} = this.state;
        return items;
    }

    requestDownloadable(location = window.location.pathname, entityId = 0) {
        const {showSuccessNotification, showErrorNotification} = this.props;

        this.setState({isLoading: true});
        // console.clear();
        // console.log(this.state);
        // console.log(this.props);
        const cart = BrowserDatabase.getItem('cart_totals');
        // console.log(cart)
        // console.log(cart);
        // console.log(JSON.parse(window.localStorage.cart_totals));
        cart.items.map((cartItem, index) => {
            entityId = cartItem.product.id;
        })

        fetchQuery(
            MostviewedQuery.getMostViewed(location, entityId)
        ).then(
            /** @namespace Component/Mostviewed/Container/requestDownloadable/success */
            (
                /** @namespace Component/Mostviewed/Container/MostviewedContainer/requestDownloadable/fetchQuery/then */
                async (data) => {
                    // console.clear();
                    // console.log(data);
                    let items = JSON.parse(data.amastyMostViewed.mostviewed_data);
                    // console.log(items);
                    // console.log('--------');
                    if (items && items.length !== 0) {
                        // console.log('here is itimes');
                        const query = ProductListQuery.getQuery({
                            args: {
                                filter: {
                                    // productsSkuArray: []
                                    productsSkuArray: items.abstractSkus
                                }
                            },
                            notRequireInfo: false,
                            noVariants: false,
                            noVariantAttributes: false
                        })
                        items.mostViewed = await fetchQuery(query);

                        // console.log(items.mostViewed);
                        delete items.abstractSkus;
                        const abstractSkus = items.mostViewed.products.items;
                        // console.log(abstractSkus);
                        const activeRules = [];
                        // console.clear();
                        // console.log(items);
                        Object.entries(items).reduce((acc, [key, products]) => {
                            // console.log(key);
                            // console.log(products);
                            // console.log(products.items);
                            if (typeof products.items === 'undefined' || typeof products.items === 'string') {
                                return;
                            }
                            // console.log(key);
                            // console.log(products.priority);
                            activeRules[products.priority] = key;
                            // activeRules.push(key);
                            abstractSkus.map(el => {
                                const index = products.items.indexOf(el.sku);
                                if (index !== -1) {
                                    el.options = (el.options) ? el.options : [];
                                    // console.log(el);
                                    products.items[index] = el;
                                }

                            });
                        }, {});
                        delete items.mostViewed;
                        // console.log(activeRules);
                        // items.activeRules = activeRules;
                        items.activeRules = activeRules.filter(function () {
                            return true;
                        });
                        // console.log(items.activeRules);
                    } else {
                        items = [];
                    }

                    // console.log(items);
                    this.setState({items, isLoading: false});
                    window.isMostviewedRequested = false;
                    // if (action === 1) {
                    //     showSuccessNotification('You will no longer receive stock alerts for this product.');
                    // }
                }),
            /** @namespace Component/Mostviewed/Container/MostviewedContainer/requestDownloadable/fetchQuery/then/catch */
            (err) => {
                showErrorNotification(getErrorMessage(err));
                this.setState({isLoading: false});
                window.isMostviewedRequested = false;
            }
        );
    }

    render() {

        return (
            <Mostviewed
                {...this.containerProps()}
                {...this.containerFunctions}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MostviewedContainer);
