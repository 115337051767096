/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { PureComponent, forwardRef , createRef} from "react";
import { ReactElement } from "Type/Common.type";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { unstable_ClassNameGenerator as ClassNameGenerator } from "@mui/material/className";
import { CustomPopUpComponentProps } from "./CustomPopUp.type";
import { CustomPopUpComponentState } from "./CustomPopUp.type";
import getStore from "Util/Store";
import Html from 'Component/Html';

ClassNameGenerator.configure((componentName) =>
    componentName.replace("MuiDialog", "CustomPopUp")
);

import "./CustomPopUp.style";
import CloseIcon from "Component/CloseIcon";
import ChevronIcon from "Component/ChevronIcon";
import { Directions } from 'Component/ChevronIcon/ChevronIcon.config';
import Loader from "Component/Loader";
import {MyAccountPageState} from "Component/MyAccountOverlay/MyAccountOverlay.config";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    const state = getStore().getState();
    const isMobile = state.ConfigReducer.device.isMobile;
    // console.log(isMobile);
    return <Slide direction={isMobile ? "up" : "down"} ref={ref} {...props} />;
});

// const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => <Slide direction="right" ref={ref} {...props} />);
// const Transition = forwardRef<unknown, TransitionProps>((props, ref) => <Slide direction="right" ref={ref} {...props} />);

/** @namespace Component/CustomPopUp/Component */
export class CustomPopUp extends PureComponent<
    CustomPopUpComponentProps,
    CustomPopUpComponentState
> {
    static defaultProps: Partial<CustomPopUpComponentProps> = {
        additionalClass: "",
        buttonClass: "",
        buttonTitle: "Button",
        heading: "",
        content: "",
        ok: "",
        cancel: "",
        source: "",
        startIcon: "",
        variant: "text",
        customFunction: false,
        customFunction2: false,
        selectedAddressId: 0,
    };

    headingRef = createRef<HTMLDivElement>();

    __construct(props: CustomPopUpComponentProps): void {
        super.__construct?.(props);
        this.handleCustomPopUpClick = this.handleCustomPopUpClick.bind(this);
        this.closePopUpAndUseFunction = this.closePopUpAndUseFunction.bind(this);

        this.handleCustomPopUpOnClose =
            this.handleCustomPopUpOnClose.bind(this);
        this.state = {
            isOpen: false,
            emailCheckbox: false,
            passwordCheckbox: false,
            guestFormOpened: false,
        };
    }

    handleCustomPopUpClose(): void {
        this.setState({isOpen: false});
        document.body.classList.remove('customPopUpActive');
    }

    handleCustomPopUpClick(): void {
        // const { isExpanding } = this.state;
        // console.clear();
        // console.log(this.props);
        // if (!isExpanding) {
        this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
        const { isOpen } = this.state;
        if (isOpen) {
            document.body.classList.remove('customPopUpActive');
            const { customFunction, selectedAddressId } = this.props;
            if (customFunction) {
                // console.log(selectedAddressId);
                // customFunction(selectedAddressId);
                //@ts-ignore
                const selectedAddressIdFromAddressBook = window.selectedAddressId;
                console.log(selectedAddressIdFromAddressBook);

                    customFunction(selectedAddressIdFromAddressBook);


            }

        }else {
            document.body.classList.add('customPopUpActive');
        }
        // this.setState({emailCheckbox: false, passwordCheckbox: false});
        // console.clear();
        // console.log(this.state);
        // console.log(this.props);

        // const { isOpen } = this.state;



        // console.log(isOpen);
        // }
    }

    closePopUpAndUseFunction(): void {
        // console.clear();
        // console.log(this.props);
        const { customFunction2 } = this.props;

        this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
        const { isOpen } = this.state;
        if (isOpen) {
            document.body.classList.remove('customPopUpActive');
            if (customFunction2) {
                customFunction2();
            }
        }else {
            document.body.classList.add('customPopUpActive');
        }

    }

    handleCustomPopUpOnClose(): void {

        const { isOpen } = this.state;
        // console.clear();
        // console.log(isOpen);
        // }
    }

    render() {
        //@ts-ignore
        // this.setState({emailCheckbox: false, passwordCheckbox: false});
        const { isOpen, emailCheckbox,passwordCheckbox, guestFormOpened } = this.state;

        // if (isOpen) {
        //     document.body.classList.add("filter-by");
        // } else {
        //     setTimeout(() => {
        //         document.body.classList.remove("filter-by");
        //     }, 1000);
        // }

        let { additionalClass, buttonClass, buttonTitle, heading, content, ok, cancel, source, startIcon, variant, customFunction, customFunction2 } = this.props;
        // console.clear();
        const newContent = Object.assign({source: source}, content);

        // console.log(content);
        // console.log(newContent);

        let newContent2 = {};
        for (const [key, value] of Object.entries(content)) {
            // console.log(key);
            // console.log(value);

            if (key === 'props') {
                // @ts-ignore
                newContent2[key] = Object.assign({source: source, headingRef: this.headingRef}, value);
            }else {
                // @ts-ignore
                newContent2[key] = value;
            }



        }
        content = newContent2;
        // @ts-ignore
        newContent2.props.source = 'source';
        // console.log(newContent2);

        // let aaa = {...content};
        // console.log(aaa);
        // aaa.props.source = source;
        // aaa.props.customPopUp = source;
        // console.log(aaa);
        // console.log(!!(ok));
        // console.log(ok);
        // console.log(!!(cancel));


        // console.log(source);

        buttonTitle = <Html content={buttonTitle}/>;

        if (source === "edit-account-information" && isOpen) {
            let {props: {
                handleChangeEmailCheckboxShow, handleChangePasswordCheckboxShow, handleChangePasswordCheckboxHide, handleChangeEmailCheckboxHide
            }} = content;
            // console.log('edit content');
            handleChangeEmailCheckboxShow();
            handleChangePasswordCheckboxHide();
                this.setState({emailCheckbox: true, passwordCheckbox: false});
        }
        if (source === "change-account-password" && isOpen) {
            let {props: {
                handleChangeEmailCheckboxShow, handleChangePasswordCheckboxShow, handleChangePasswordCheckboxHide, handleChangeEmailCheckboxHide
            }} = content;
            // console.log('edit password');
            handleChangePasswordCheckboxShow();
            handleChangeEmailCheckboxHide();
                this.setState({emailCheckbox: false, passwordCheckbox: true});
        }

        let defaultCustomPopUpId = 'CustomPopUp';

        if (source === "change-shipping-method") {
            defaultCustomPopUpId = 'ChangeShippingMethod';
        }

        let shippingBillingHeading = '';

        if  (source === "checkout-guest-form") {
            content.props.source = source;
            content.props.headingRef = this.headingRef;
            shippingBillingHeading = 'Shipping Information';
        }

        if  (source === "kb-guest-form") {
            content.props.source = source;
            content.props.headingRef = this.headingRef;
            shippingBillingHeading = '';
        }

        if (source === "customer-address-book shipping") {
            shippingBillingHeading = 'Shipping Information';
        }
        if (source === "customer-address-book billing") {
            shippingBillingHeading = 'Billing Information';
        }
        // console.log(this.state);
        // if  (source === "customer-address-book" && !guestFormOpened) {
        //     customFunction();
        //     console.log(content);
        //     console.log(customFunction);
        //     this.setState({guestFormOpened: true});
        //     // content.props.source = source;
        //     // content.props.headingRef = this.headingRef;
        // }

        // console.log(this.state);


        const state = getStore().getState();
        const isMobile = state.ConfigReducer.device.isMobile;
        // return 'works';
        let popupTransition = {}
        if (isMobile) {
            popupTransition = {
                TransitionComponent: Transition,
                transitionDuration: 300
            };

        }
        //@ts-ignore
        window.closeCustomPopUp = this.handleCustomPopUpClose.bind(this);
        return (
            <div className={"CustomPopUp " + source + ' ' + additionalClass}>

                {
                    (!!(shippingBillingHeading)) ?
                        <div block="Checkout" elem="Header">
                            <div block="Checkout" elem="Title">
                                {shippingBillingHeading}
                            </div>
                            <Button
                                id={defaultCustomPopUpId}
                                className={buttonClass}
                                variant={variant}
                                onClick={this.handleCustomPopUpClick}
                                // startIcon={<ChevronIcon direction={ Directions.BOTTOM } />}
                                startIcon={startIcon}
                            >
                                {buttonTitle}
                            </Button>
                        </div>
                        :
                        <Button
                            id={defaultCustomPopUpId}
                            className={buttonClass}
                            variant={variant}
                            onClick={this.handleCustomPopUpClick}
                            // startIcon={<ChevronIcon direction={ Directions.BOTTOM } />}
                            startIcon={startIcon}
                        >
                            {buttonTitle}
                        </Button>
                }

                {/*<Button*/}
                {/*    id="CustomPopUp"*/}
                {/*    className={buttonClass}*/}
                {/*    variant={variant}*/}
                {/*    onClick={this.handleCustomPopUpClick}*/}
                {/*    // startIcon={<ChevronIcon direction={ Directions.BOTTOM } />}*/}
                {/*    startIcon={startIcon}*/}
                {/*>*/}
                {/*    {buttonTitle}*/}
                {/*</Button>*/}
                <Dialog
                    className={"CustomPopUp " + source + ' ' + additionalClass}
                    open={isOpen}
                    {...popupTransition}
                    // TransitionComponent={Transition}
                    // transitionDuration={300}
                    // keepMounted
                    onClose={this.handleCustomPopUpOnClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <div id="CustomPopUpLoader"><Loader isLoading={true}/></div>

                    <div className="CustomPopUp-Heading">
                        <DialogTitle ref={ this.headingRef }>
                            {heading}
                            {/* <Button onClick={this.handleCustomPopUpClick}>Close</Button> */}
                        </DialogTitle>
                        <div
                            id="CustomPopUp-Close"
                            className="CustomPopUp-Close"
                            onClick={this.handleCustomPopUpClick}
                        >
                            <CloseIcon />
                        </div>
                    </div>
                    <DialogContent>
                        {content}
                        {/* <DialogContentText id="alert-dialog-slide-description">
                    Let Google help apps determine location. This means sending anonymous
                    location data to Google, even when no apps are running.
                  </DialogContentText> */}
                    </DialogContent>

                    {
                        (customFunction2) ?
                            <DialogActions>
                                <button
                                    id="CustomPopUpOk"
                                    // disabled
                                    className="Button"
                                    mix={{block: ok.replace(/\s+/g, '')}}
                                    onClick={this.closePopUpAndUseFunction}
                                >
                                    {ok}
                                </button>
                            </DialogActions>
                            : ''
                    }
                </Dialog>
            </div>
        );

        // const { direction, angle, type, content } = this.props;

        // return (
        //     <div
        //         block="CustomPopUp"
        //         mods={{ direction, angle, type }}
        //         mix={{ block: content }}
        //     />
        // );
    }
}

export default CustomPopUp;
